import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

	constructor() { }

	public ngOnInit(): void {
		// this.loadScript('https://platform.linkedin.com/badges/js/profile.js');
	}

	public loadScript(url: string): void {
		const body = <HTMLDivElement>document.body;
		const script = document.createElement('script');
		script.innerHTML = '';
		script.src = url;
		script.async = true;
		script.defer = true;
		body.appendChild(script);
	}

}
