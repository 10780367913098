<div class="container pb-5">
    <div class="row">
        <h1 class="w-100 text-center">{{'contact title' | translate}}</h1>
        <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
            <div class="badge-base LI-profile-badge" data-locale="fr_FR" data-size="medium" data-theme="dark"
                data-type="VERTICAL" data-vanity="james-fournier-programmeur-web" data-version="v1">
                <a class="badge-base__link LI-simple-link"
                    href="https://ca.linkedin.com/in/james-fournier-programmeur-web?trk=profile-badge">
                    {{'linkedin' | translate}}
                </a>
            </div>
        </div>
        <p class="col-12 col-md-6 col-lg-8">
            {{'contact text' | translate}}
            <a href="mailto:programmation.jaf@gmail.com">programmation.jaf@gmail.com</a>.
        </p>
    </div>
</div>