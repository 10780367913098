<nav class="navbar navbar-expand-sm fixed-top" id="navPrincipale">
    <div class="container">
        <a class="nav-link" (click)="setLang()">{{translate.lang == 'fr'? 'english':'français'}}?</a>
        <button class="navbar-toggler navbar-toggler-right" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            <span aria-hidden="false" hidden>{{'show navbar blind' | translate}}</span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a routerLink="home" class="nav-link" routerLinkActive="active">{{'home tab' | translate}}</a>
                </li>
                <li class="nav-item">
                    <a routerLink="projects"  class="nav-link" routerLinkActive="active">{{'projects tab' | translate}}</a>
                </li>
                <li class="nav-item">
                    <a routerLink="contactme" class="nav-link" routerLinkActive="active">{{'contact tab' | translate}}</a>
                </li>
            </ul>
        </div>
    </div>
</nav>