<app-navbar></app-navbar>
<div id="content">
    <router-outlet></router-outlet>
</div>
<footer>
    <div class="row mx-0">
        <div class="col-12">
            <p class="col-12 text-center mb-0">
                <small>{{'last modifications' | translate}}: 2024/06/03</small>
            </p>
            <p class="col-12 text-center">{{ 'copyright' | translate }} &copy; 2018-2024
            </p>
        </div>
    </div>
</footer>