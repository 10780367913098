import { Component } from '@angular/core';
import { TranslateService } from '../../services/translate.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

	constructor(public translate: TranslateService) { }

	public setLang(): void {
		this.translate.changeLang();
	}

}
