<div class="container py-5">
    <div class="row col-12 col-md-6 align-items-center mx-auto">
        <div class="col-4 px-0">
            <img class="img-fluid center-block" src="assets/img/chat_assis.jpg" alt="cat">
        </div>
        <div class="col-4 px-0">
            <img class="img-fluid" src="favicon.ico" alt="logo">
        </div>
        <div class="col-4 px-0">
            <img class="img-fluid" src="assets/img/chat_couche.jpg" alt="cat">
        </div>
    </div>

    <div class="row">
        <h1 class="col-12 text-center">{{'404 title' | translate}}</h1>
        <p class="col-12 text-center">{{'404 desc' | translate}} <a routerLink="/">{{'clicking here' | translate}}</a>.</p>
    </div>
</div>