<div class="container">
    <div class="row">
        <h1 class="col-12 text-center">{{'project title' | translate}}</h1>
        <p>{{'project desc' | translate}}</p>
        <h2 class="col-12 text-center">{{'work title' | translate}}</h2>
        <p>{{'work desc' | translate}}</p>
        <h2 class="col-12 text-center">{{'projects done title' | translate}}</h2>
        <h3 class="col-12 text-center">{{'school projects title' | translate}}</h3>
        <div class="col-12 col-md-6 border mx-auto">
            <h4 class="col-12 text-center">Solitaire</h4>
            <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/-LHqTcv2_AM" allow="autoplay; encrypted-media"
                    allowfullscreen></iframe>
            </div>
            <p>{{'solitaire desc' | translate}}</p>
        </div>
        <div class="col-12 col-md-6 border mx-auto">
            <h4 class="col-12 text-center">LinkBattle v1.0.0</h4>
            <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/65Y0BeP5_do" allow="autoplay; encrypted-media"
                    allowfullscreen></iframe>
            </div>
            <p>{{'linkbattle v1 desc' | translate}}</p>
        </div>
        <div class="col-12 col-md-6 border mx-auto">
            <h4 class="col-12 text-center">LinkBattle 2.0.0</h4>
            <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/yqNogyGzjT0" allow="autoplay; encrypted-media"
                    allowfullscreen></iframe>
            </div>
            <p>{{'linkbattle v2 desc' | translate}}</p>
        </div>
        <h3 class="col-12 text-center">{{'personal projects title' | translate}}</h3>
        <p>{{'personal projects desc' | translate}}</p>
    </div>
</div>