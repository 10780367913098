import { Component } from '@angular/core';

@Component({
	selector: 'app-four-oh-four',
	templateUrl: './four-oh-four.component.html',
	styleUrls: ['./four-oh-four.component.css']
})
export class FourOhFourComponent {

	constructor() { }

}
