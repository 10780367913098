<div class="container">
    <div class="row col-12 mx-0">
        <h1 class="col-12 text-center">{{ 'welcome' | translate }}</h1>
        <h2 class="col-12 text-center">James Fournier</h2>
    </div>
</div>
<section class="page-section" id="informatique">
    <div class="container">
        <h3 class="col-12 text-center">
            <span class="fa fa-code" aria-hidden="true"></span>
            {{'informatique' | translate }}
        </h3>
        <div class="row col-12 no-gutters">
            <img src="assets/img/user.png" alt="photo informatique" class="rounded-circle mx-auto" width="100px" height="100px">
        </div>
        <p>
            <span class="fa fa-code" aria-hidden="true"></span>
            {{'intro' | translate}}
            <a routerLink="/projects">{{'clicking here' | translate}}</a>.
        </p>
        <h3 class="col-12 text-center">
            {{'aboutMeTitle' | translate }}
        </h3>
        <p>
            {{'aboutMe' | translate }}
        </p>
        <h3 class="col-12 text-center">
            Jafiot
        </h3>
        <p>
            {{'jafiot' | translate }}
        </p>
        <h3 class="col-12 text-center">
            ACCEO Transport
        </h3>
        <p>
            {{'acceo transport' | translate }}
        </p>
        <h4 class="col-12 text-center">{{'languages mastered' | translate}}</h4>
        <div #progLangContainer [ngClass]="{'row col-12 justify-content-center': true, 'onScreen': isProgLangScrolledIntoView}">
            <div *ngFor="let progLanguage of this.progLanguages; index as i" class="mx-3 text-center progLang" [style]="'--animation-order:' + i + ';'">
                <img *ngIf="progLanguage.imagePath != null" [src]="progLanguage.imagePath" alt="" class="icon-5x">
                <span *ngIf="progLanguage.imagePath == null" [class]="progLanguage.class" aria-hidden="true"></span>

                <p class="text-center">{{progLanguage.displayName}}</p>
            </div>
        </div>
        <h4 class="col-12 text-center">{{'server' | translate}}</h4>
        <p class="col-12 text-center">{{'server desc' | translate}}</p>
        <div #progServerContainer [ngClass]="{'row col-12 justify-content-center': true, 'onScreen': isProgServerScrolledIntoView}">
            <div *ngFor="let progServer of this.progServers; index as i" class="mx-3 text-center progLang" [style]="'--animation-order:' + i + ';'">
                <img *ngIf="progServer.imagePath != null" [src]="progServer.imagePath" alt="" class="icon-5x">
                <span *ngIf="progServer.imagePath == null" [class]="progServer.class" aria-hidden="true"></span>

                <p class="text-center">{{progServer.displayName}}</p>
            </div>
        </div>
        <h4 class="col-12 text-center">{{'hardware' | translate}}</h4>
        <p>{{'hardware desc' | translate}}
            <span class="far fa-smile fa-lg" aria-hidden="true"></span>{{'hardware conclusion' | translate}}
        </p>
    </div>
</section>
<section class="page-section" id="zelda">
    <div class="container">
        <h3 class="col-12 text-center">Zelda</h3>
        <div class="row col-12 no-gutters">
            <img src="assets/img/zelda.png" alt="zelda icon" class="mx-auto">
        </div>
        <p>{{'zelda intro' | translate}}</p>
        <h4 class="col-12 text-center">{{'history' | translate}}</h4>
        <p>{{'zelda history' | translate}}</p>
        <h4 class="col-12 text-center">{{'music title' | translate}}</h4>
        <p>{{'zelda music' | translate}}</p>
    </div>
</section>
<section class="page-section" id="aquariophilie">
    <div class="container">
        <h3 class="col-12 text-center">{{'aquariophilie title' | translate}}</h3>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <div id="myCarousel" class="carousel slide well text-center" data-ride="carousel">
                    <ul class="carousel-indicators">
                        <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                        <li data-target="#myCarousel" data-slide-to="1"></li>
                        <li data-target="#myCarousel" data-slide-to="2"></li>
                        <li data-target="#myCarousel" data-slide-to="3"></li>
                        <li data-target="#myCarousel" data-slide-to="4"></li>
                        <li data-target="#myCarousel" data-slide-to="5"></li>
                        <li data-target="#myCarousel" data-slide-to="6"></li>
                        <li data-target="#myCarousel" data-slide-to="7"></li>
                    </ul>
                    <div class="carousel-inner" role="listbox">
                        <div class="carousel-item active">
                            <img class="d-block img-fluid" src="assets/img/platy.jpg" alt="photo platy">
                            <div class="carousel-caption">
                                <p>Platy</p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="d-block img-fluid" src="assets/img/guppy.jpg" alt="photo guppy">
                            <div class="carousel-caption">
                                <p>Guppy</p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="d-block img-fluid" src="assets/img/molly.jpg" alt="photo molly">
                            <div class="carousel-caption">
                                <p>Molly</p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="d-block img-fluid" src="assets/img/crevette.jpg" alt="photo crevette cerise">
                            <div class="carousel-caption">
                                <p>{{'shrimp' | translate}}</p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="d-block img-fluid" src="assets/img/bébé_platty.jpg" alt="photo alevins platys">
                            <div class="carousel-caption">
                                <p>{{'alevins platy' | translate}}</p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="d-block img-fluid" src="assets/img/bebe_guppy.jpg" alt="photo alevins guppys">
                            <div class="carousel-caption">
                                <p>{{'alevins guppy' | translate}}</p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="d-block img-fluid" src="assets/img/aquaterrarium.jpg" alt="photo aquaterrarium">
                            <div class="carousel-caption">
                                <p>{{'aquaterrarium carousel' | translate}}</p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="d-block img-fluid" src="assets/img/aquaterrarium2.jpg" alt="photo aquaterrarium">
                            <div class="carousel-caption">
                                <p>{{'aquaterrarium avec alevins' | translate}}</p>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#myCarousel" data-slide="prev">
                        <span class="carousel-control-prev-icon"></span>
                    </a>
                    <a class="carousel-control-next" href="#myCarousel" data-slide="next">
                        <span class="carousel-control-next-icon"></span>
                    </a>
                </div>
            </div>
            <p class="col-12 col-md-6 col-lg-8">
                {{'aquariophilie first' | translate}}
                <a [pTooltip]="'aquariophilie def' | translate">{{'aquariophilie' | translate }}</a>.
                {{'aquariophilie second' | translate }}
            </p>
            <div class="col-12 col-md-6">
                <h4 class="col-12 text-center">{{'aquariophilie repro title' | translate }}</h4>
                <p>
                    {{'aquariophilie repro first' | translate }}
                    <a [pTooltip]="'alevins def' | translate" [hidden]="translate.lang == 'en'">{{'alevins'
                        | translate }}</a>
                    {{'aquariophilie repro second' | translate }}
                </p>
            </div>
            <div class="col-12 col-md-6">
                <h4 class="col-12 text-center">{{'aquaterrarium title' | translate }}</h4>
                <p>
                    {{'aquaterrarium first' | translate }}
                    <a [pTooltip]="'aquaterrarium def' | translate">{{'aquaterrarium' | translate
                        }}</a>
                    {{'aquaterrarium second' | translate }}
                </p>
            </div>
        </div>
    </div>
</section>
<section class="page-section" id="music">
    <div class="container">
        <h3 class=" col-12 text-center">{{'music title' | translate }}</h3>
        <div class="row col-12 no-gutters">
            <img src="assets/img/drum.jpg" alt="photo informatique" class="rounded-circle mx-auto" width="100" height="100">
        </div>
        <p>{{'music' | translate }}</p>
        <div class="row">
            <div class="col-12 col-md-6 border no-gutters p-2 container-fluid">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/B0BXiSKzQ9E" allow="autoplay; encrypted-media"
                        allowfullscreen></iframe>
                </div>
                <p>{{'the day that never comes' | translate }}</p>
            </div>
            <div class="col-12 col-md-6 border no-gutters p-2 container-fluid">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/FE-FSrdq_jY" allow="autoplay; encrypted-media"
                        allowfullscreen></iframe>
                </div>
                <p>{{'the unforgiven' | translate }}</p>
            </div>
            <div class="col-12 col-md-6 border no-gutters p-2 container-fluid">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed//so-Dls10EeM" allow="autoplay; encrypted-media"
                        allowfullscreen></iframe>
                </div>
                <p>{{'hail to the king' | translate }}</p>
            </div>
        </div>
    </div>
</section>