import { Injectable } from '@angular/core';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class TranslateService {

	private _lang: string;

	get lang(): string {
		return this._lang;
	}

	set lang(value: string) {
		this._lang = value;
		this.saveLang(value);
		this.translate.use(value);
	}

	constructor(private translate: NgxTranslateService) {
		const cookieLang = this.getSavedLang();
		if (cookieLang == "fr")
			this.lang = "fr";
		else if (cookieLang == "en")
			this.lang = "en";
		else
			this.autoSetLang();
		translate.setDefaultLang("fr");
	}

	public changeLang(): void {
		this.lang = this.lang == "fr" ? "en" : "fr";
	}

	private autoSetLang(): void {
		const userLang = navigator.language;
		if (userLang.includes("en"))
			this.lang = "en";
		else
			this.lang = "fr";
	}

	private saveLang(lang: string): void {
		localStorage.setItem("lang", lang);
	}

	private getSavedLang(): string {
		return localStorage.getItem("lang");
	}
}
