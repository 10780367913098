import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TooltipModule } from 'primeng/tooltip';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { ContactComponent } from './components/contact/contact.component';
import { FourOhFourComponent } from './components/four-oh-four/four-oh-four.component';
import { HomeComponent } from './components/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ProjectComponent } from './components/project/project.component';
import { TranslateService } from './services/translate.service';

const appRoutes: Routes = [
	{ path: 'home', component: HomeComponent },
	{ path: 'projects', component: ProjectComponent },
	{ path: 'contactme', component: ContactComponent },
	{ path: 'not-found', component: FourOhFourComponent },
	{ path: '', component: HomeComponent, pathMatch: "full" },
	{ path: '**', component: FourOhFourComponent }
];

/**
 * Initializes the text files for translations
 * @param httpClient Service to send HTTP requests
 */
function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(httpClient, "./assets/i18n/", `.json?version=${environment.version}`);
}

@NgModule({
	declarations: [
		AppComponent,
		FourOhFourComponent,
		HomeComponent,
		ProjectComponent,
		ContactComponent,
		NavbarComponent
	],
	imports: [
		BrowserModule,
		CommonModule,
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		RouterModule.forRoot(appRoutes, {}),
		TooltipModule
	],
	providers: [
		TranslateService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
