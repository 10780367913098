import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit {

	protected readonly progLanguages = [
		{
			imagePath: "assets/icons/angular.png",
			displayName: "Angular"
		},
		{
			imagePath: "assets/icons/ts.svg",
			displayName: "TypeScript"
		},
		{
			imagePath: "assets/icons/html5.svg",
			displayName: "HTML5"
		},
		{
			imagePath: "assets/icons/css3.svg",
			displayName: "CSS3"
		},
		{
			imagePath: "assets/icons/bootstrap.svg",
			displayName: "Bootstrap"
		},
		{
			imagePath: "assets/icons/js.svg",
			displayName: "JavaScript"
		},
		{
			imagePath: null,
			displayName: "jQuery",
			class: "devicon-jquery-plain colored fa-5x"
		},
		{
			imagePath: "assets/icons/nestjs.svg",
			displayName: "NestJS"
		},
		{
			imagePath: "assets/icons/php.svg",
			displayName: "PHP"
		},
		{
			imagePath: null,
			displayName: "Symfony",
			class: "devicon-symfony-original fa-5x"
		},
		{
			imagePath: null,
			displayName: "Android",
			class: "devicon-android-plain colored fa-5x"
		},
		{
			imagePath: "assets/icons/delphi.svg",
			displayName: "Delphi"
		},
		{
			imagePath: "assets/icons/mariadb.svg",
			displayName: "MariaDB"
		},
		{
			imagePath: null,
			displayName: "MySQL",
			class: "devicon-mysql-plain colored fa-5x"
		},
		{
			imagePath: null,
			displayName: "MongoDB",
			class: "devicon-mongodb-plain colored fa-5x"
		},
		{
			imagePath: "assets/icons/java.svg",
			displayName: "Java"
		},
		{
			imagePath: "assets/icons/python.svg",
			displayName: "Python"
		},
		{
			imagePath: "assets/icons/linux.svg",
			displayName: "bash"
		}
	];

	protected readonly progServers = [
		{
			imagePath: null,
			displayName: "NGINX",
			class: "devicon-nginx-plain colored fa-5x"
		},
		{
			imagePath: "assets/icons/apache.svg",
			displayName: "Apache 2"
		},
		{
			imagePath: null,
			displayName: "Debian",
			class: "devicon-debian-plain colored fa-5x"
		},
		{
			imagePath: null,
			displayName: "Docker",
			class: "devicon-docker-plain colored fa-5x"
		}
	];

	@ViewChild('progLangContainer', { static: false }) private progLangContainer: ElementRef<HTMLDivElement>;

	protected isProgLangScrolledIntoView = false;

	@ViewChild('progServerContainer', { static: false }) private progServerContainer: ElementRef<HTMLDivElement>;

	protected isProgServerScrolledIntoView = false;

	// @HostListener('window:scroll', ['$event'])
	// protected isScrolledIntoView(): void {
	// 	if (!this.isProgLangScrolledIntoView) // don't want to set it to false when it's true
	// 		this.isProgLangScrolledIntoView = this.getIsElementVisible(this.progLangContainer);

	// 	if (!this.isProgServerScrolledIntoView) // don't want to set it to false when it's true
	// 		this.isProgServerScrolledIntoView = this.getIsElementVisible(this.progServerContainer);
	// }

	constructor(protected translate: TranslateService) { }

	public ngAfterViewInit(): void {

		setTimeout(() => {

			const progLanguageobserver = new IntersectionObserver(([entry], observer) => {
				if (entry.isIntersecting) {
					observer.disconnect();
					this.isProgLangScrolledIntoView = true;
				}
			}, { threshold: this.calculateThreshold(this.progLangContainer) });

			progLanguageobserver.observe(this.progLangContainer.nativeElement);


			const progServerobserver = new IntersectionObserver(([entry], observer) => {
				if (entry.isIntersecting) {
					console.log(entry.boundingClientRect.top);
					observer.disconnect();
					this.isProgServerScrolledIntoView = true;
				}
			}, { threshold: this.calculateThreshold(this.progServerContainer) });

			progServerobserver.observe(this.progServerContainer.nativeElement);
		}, 300); // let dom render to have right sizes and positions
	}

	private calculateThreshold(element: ElementRef<HTMLDivElement>): number {
		const screeenSurface = window.innerHeight * window.innerWidth;

		const rect = element.nativeElement.getBoundingClientRect();
		const divSurface = rect.width * rect.height;

		if (screeenSurface / 4 > divSurface)
			return 0.5;
		else if (screeenSurface / 8 > divSurface)
			return 0.25;
		else if (screeenSurface / 16 > divSurface)
			return 0.125;
		else
			return 0.01;
	}

}
